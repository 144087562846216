
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import * as Yup from "yup";
import Toast from "@/common/ts/Toast.js";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const submitButton = ref<HTMLButtonElement | null>(null);

    //Create form validation object
    const login = Yup.object().shape({
      mobile: Yup.string().required("请输入手机号码"),
      user_password: Yup.string()
        .min(6, "密码长度至少6位")
        .required("请输入登录密码"),
    });

    //Form submit function
    const onSubmitLogin = (values) => {
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      store
        .dispatch(Actions.LOGIN, values)
        .then(() => {
          router.push({ name: "dashboard" });
        })
        .catch(() => {
          Toast.warning(store.getters.getErrors[0]);
        })
        .finally(() => {
          //Deactivate indicator
          submitButton.value?.removeAttribute("data-kt-indicator");
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        });

      // // Dummy delay
      // setTimeout(() => {
      //   // Send login request
      //   store
      //     .dispatch(Actions.LOGIN, values)
      //     .then(() => {
      //       Swal.fire({
      //         text: "You have successfully logged in!",
      //         icon: "success",
      //         buttonsStyling: false,
      //         confirmButtonText: "Ok, got it!",
      //         customClass: {
      //           confirmButton: "btn fw-bold btn-light-primary",
      //         },
      //       }).then(function () {
      //         // Go to page after successfully login
      //         router.push({ name: "dashboard" });
      //       });
      //     })
      //     .catch(() => {
      //       const [error] = Object.keys(store.getters.getErrors);
      //       Swal.fire({
      //         text: store.getters.getErrors[error],
      //         icon: "error",
      //         buttonsStyling: false,
      //         confirmButtonText: "Try again!",
      //         customClass: {
      //           confirmButton: "btn fw-bold btn-light-danger",
      //         },
      //       });
      //     });

      //   //Deactivate indicator
      //   submitButton.value?.removeAttribute("data-kt-indicator");
      //   // eslint-disable-next-line
      //   submitButton.value!.disabled = false;
      // }, 2000);
    };

    return {
      onSubmitLogin,
      login,
      submitButton,
    };
  },
});
